import React from 'react';
import MarketingLayout from '../components/layout/MarketingLayout';
import PageHeader from '../components/layout/PageHeader';
import Button from '../components/ui/Button';
import PageSection from '../components/ui/PageSection';
import Spacing from '../components/ui/Spacing';
import Text from '../components/ui/Text';
import TextContainer from '../components/ui/TextContainer';

const PressPage: React.SFC = () => (
  <MarketingLayout title="Press">
    <Spacing bottom={5}>
      <PageHeader title="Press Resources" description="" />
    </Spacing>
    <PageSection>
      <TextContainer center>
        <h4>Contact Us</h4>
        <Spacing top={2} bottom={6}>
          We’re happy to talk about how teams can share knowledge. Send us a
          note at{' '}
          <a href="mailto:press@slab.com">
            <Text link heavyUnderline>
              press@slab.com
            </Text>
          </a>
          .
          <Spacing top={2}>
            <Button to="https://static.slab.com/assets/Slab_Press_Kit.zip">
              Download Press Kit
            </Button>
          </Spacing>
        </Spacing>
        <h4>Mission</h4>
        <Spacing top={2} bottom={6}>
          Slab’s mission is to build a worthy home for your team’s most valuable
          knowledge. Founded by a seasoned team of builders and operators, we
          have experienced first hand challenges scaling coordination and
          collaboration at hyper-growth companies.
        </Spacing>
        <h4>Press Coverage</h4>
        <Spacing top={2} bottom={6}>
          <ul>
            <li>
              <a href="https://gigaom.com/report/state-of-work-technology-volume-3-content-centric-work-technology/">
                <Text link heavyUnderline>
                  Gigaom: State of Work Technology: Volume 3 Content-Centric
                  Work Technology
                </Text>
              </a>
              <Text xxSmall muted>
                March 20, 2019
              </Text>
            </li>
            <li>
              <a href="https://techcrunch.com/2018/02/06/slab-raises-2-2m-to-build-tools-for-an-internal-employee-information-nexus/">
                <Text link heavyUnderline>
                  TechCrunch: Slab raises $2.2M to build tools for an internal
                  employee information nexus
                </Text>
              </a>
              <Text xxSmall muted>
                Feb 6, 2018
              </Text>
            </li>
          </ul>
        </Spacing>
      </TextContainer>
    </PageSection>
  </MarketingLayout>
);

export default PressPage;
